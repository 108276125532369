import React from "react"
import PropTypes from "prop-types"

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <footer className="w-full px-6 py-1 mt-10 text-sm text-left bg-primary">
        © {new Date().getFullYear()} Archi Pornic
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
